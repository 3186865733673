.derivatives-trading-page {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  & > .grid {
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    & > div {
      min-height: calc(100vh - 80px);
      max-height: calc(100vh - 80px);
    }
  }
  .panel {
    @apply border rounded-lg h-full bg-white;
  }
}
